<template>
    <v-container>
        <AdBTWR v-if="!user.subscribed"/>

        <v-container class="mt-3 mb-10">
            <v-row align="center">
<!--                <v-col cols="12">-->
<!--                    <v-card color="rfnavy" dark class="my-0 elevation-5 d-flex flex-wrap" shaped>-->
<!--                        <div class="flex-shrink-1">-->
<!--                            <v-card-text>-->
<!--                                <v-img-->
<!--                                    :src="require('../assets/logo-stacked-dark.png')"-->
<!--                                    contain-->
<!--                                    max-height="200"-->
<!--                                />-->
<!--                            </v-card-text>-->
<!--                        </div>-->
<!--                        <div class="flex-grow-1">-->
<!--                            <v-card-title>-->
<!--                                <h2 class="display-1 font-weight-light">-->
<!--                                    About Us-->
<!--                                </h2>-->
<!--                            </v-card-title>-->
<!--                            <v-card-text>-->
<!--                                <span class="headline font-weight-thin"><strong>myGMRS</strong> is a two-way radio directory for listing repeaters in the General Mobile Radio Service (GMRS) founded in 2006.</span>-->
<!--                            </v-card-text>-->
<!--                        </div>-->
<!--                    </v-card>-->
<!--                </v-col>-->

                                <v-col cols="12" sm="4">
                                    <v-sheet :color="$vuetify.theme.dark ? 'rfnavy' : ''" class="pa-5" rounded>
                                        <v-img
                                            :src="require($vuetify.theme.dark ? '../assets/logo-stacked-dark.png' : '../assets/logo-stacked-light.png')"
                                            contain height="200"
                                        />
                                    </v-sheet>
                                </v-col>
                                <v-col cols="12" sm="8">
                                    <v-card color="rfnavy" dark class="my-0 elevation-5" shaped>
                                        <v-card-title>
                                            <h2 class="display-1 font-weight-light">
                <!--                                <v-icon large class="mr-1">mdi-book-information-variant</v-icon>-->
                                                About Us
                                            </h2>
                                        </v-card-title>
                                        <v-card-text>
                                            <span class="headline font-weight-thin"><strong>myGMRS</strong> is a two-way radio directory for listing repeaters in the General Mobile Radio Service (GMRS) founded in 2006.</span>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" lg="6" xl="7">
                    <h2 class="title">Beginnings</h2>

                    <p class="text-justify">myGMRS.com was created in September 2006 by
                        <router-link to="/user/rdunajewski">Rich Dunajewski</router-link>
                        (WQEJ577 / N2DLX). At the time, there were no websites for GMRS which allowed users to supply
                        their own repeater information and automatically display it. A couple websites had repeater
                        information, but they required repeater owners to email their information over to the webmaster
                        and the webmaster had to edit their web site to list the information. This was a slow a tedious
                        process, as it required a human to add every listing.
                    </p>

                    <p class="text-justify">Rich was just starting his professional career as a software engineer, and
                        utilized server-side technologies to create a dynamic website where users could log in and post
                        their own information in a completely automated way. This revolutionized the radio service as it
                        allowed information to spread more readily and without a human having to allow each edit. As the
                        database grew, this automation become more and more important so one person didn't hinder the
                        flow of information.</p>

                    <p class="text-justify">Another problem with existing websites at the time was the information
                        posted was available to the public. Sensitive information such as CTCSS/DCS tones were posted
                        for anyone to see, regardless of if the visitor was a licensed GMRS individual. To solve this,
                        myGMRS required all users to supply their GMRS callsign so their license could be validated
                        automatically upon registration. By requiring visitors to be logged in to view any sensitive
                        repeater information, repeaters owners could be assured that their information was only visible
                        to licensed individuals.</p>


                    <h2 class="title">Growth and Expansion</h2>

                    <p class="text-justify">Through the years since 2006, myGMRS has experienced incredible growth in
                        number of users and repeater listings. In fact, myGMRS has been credited with growth of the GMRS
                        service in general, bringing more and more licensees to what was a very underutilized radio
                        service.</p>
                </v-col>

                <v-col cols="12" lg="6" xl="5" align="right">
                    <v-card class="px-5" max-width="650" flat color="transparent">
                        <v-timeline
                            align-top
                            :dense="$vuetify.breakpoint.lgAndDown"
                        >
                            <v-timeline-item
                                v-for="(item, i) in timeline"
                                :key="i"
                                :color="item.color"
                                :icon-color="item.iconColor"
                                :icon="item.icon"
                                :fill-dot="!!item.icon"
                                :small="!item.icon"
                            >
                                <v-card color="rfaccent2" dark tilex>
                                    <v-card-title class="py-1 font-weight-light">{{ item.title }}</v-card-title>
                                    <v-card-text class="subtitle-1 white almostblack--text text-left pt-4">
                                        {{ item.text }}
                                    </v-card-text>
                                </v-card>
                            </v-timeline-item>
                        </v-timeline>
                    </v-card>
                </v-col>
            </v-row>

        </v-container>

    </v-container>

</template>

<script>
    import AdBTWR from '@/components/ads/BTWR.vue';
    import { useTitle } from '@vueuse/core';

    export default {
        props: ['user'],

        components: {AdBTWR},

        data: () => ({
            timeline: [
                {
                    title: 'September 2, 2006',
                    color: 'primary',
                    iconColor: 'white',
                    icon: 'mdi-baby-carriage',
                    text: 'myGMRS.com is founded'
                },
                {title: 'March 31, 2009', text: 'Reached its first 1,000 users'},
                {title: 'April 26, 2016', text: '1,000 repeaters added'},
                {title: 'September 21, 2018', text: 'Reached 10,000 users'},
                {title: 'January 18, 2021', text: 'Reached 20,000 users'},
                {title: 'December 27, 2021', text: 'Reached 30,000 users'},
                {title: 'January 1, 2022', text: 'Launched new website'},
            ]
        }),

        mounted() {
            useTitle('About Us - myGMRS.com');
        }
    }
</script>
